import React, { useState } from 'react'
import logo from '../assets/logo.png'
import smoke from '../assets/smoke.png'
import table from '../assets/table.png'
import FormModal from './FormModal'

export default function Header() {
    const [isOpen, setIsOpen] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
    return (
        <div className=" relative" style={{ backgroundImage: `url("${smoke.src}")` }}>
            {/* <img src={smoke} alt="" className="w-full absolute xl:-bottom-52 lg:-bottom-36 md:-bottom-28 -bottom-20 -z-10 " /> */}
            <div className="flex justify-center pt-8 pb-10">
                <img src={logo} alt=" " className="h-[9vh]" />
            </div>
            {
                backdrop ?
                    <div className="bg-black fixed z-50 bg-opacity-50 inset-0"></div>
                    : null
            }

            <FormModal isOpen={isOpen} setIsOpen={setIsOpen} setBackdrop={() => { setBackdrop(false) }} />


            <div className="space-y-5">
                <div className=" space-y-4 sm:space-y-8">
                    <div className="font-lara text-3xl text-center tracking-widest font sm:text-[6.5vh] flex justify-center">
                        <div className="sm:space-y-2">
                            <h1 className="select-none">Welcome to the new</h1>
                            <h1 className="select-none">generation of dining</h1>
                        </div>

                    </div>
                    <div className="font-public-sans text-sm sm:text-[2.6vh] font-extralight text-center">
                        <p className="select-none tracking-[3px]">Culinary experiences from the best chefs</p>
                    </div>

                </div>

                <div className=" flex justify-center">
                    <div className="relative text-center font-lara opacity-[20%]">
                        <p className="text-3xl sm:text-[5vh] select-none">Opening</p>
                        <p className="text-3xl sm:text-[6vh] select-none -translate-y-1 sm:-translate-y-2">Soon</p>
                    </div>

                </div>
            </div>
            <div className="flex justify-center">
                <img src={table} alt="" className="sm:px-0 select-none h-[40vh] object-contain w-max" fetchpriority="high" />
            </div>
            <div className="flex justify-center">
                <button
                    className="font-lara tracking-widest text-srve-green z-20 border -translate-y-12 border-srve-green py-4 hover:scale-110 transition duration-500 delay-150 ease-in-out px-16 bg-[#181818] bg-opacity-50 text-2xl"
                    onClick={() => { setIsOpen(true); setBackdrop(true) }}
                >
                    Get Invited
                </button>
            </div>





        </div>
    )
}

import './App.css';
import Header from './components/Header';

function App() {
  return (
    <div className="min-h-screen max-h-screen overflow-x-hidden transition-all">
      <Header />
      
    </div>
  );
}

export default App;
 
import React from 'react'

export default function FormButton({icon, title}) {
    return (
        <div className="flex justify-center items-center transition">
            <div className="space-y-2">
                <div className="flex justify-center">
                    <img src={icon} alt="" className="" />
                </div>
                <h3 className="font-lara text-2xl tracking-[2px] text-center">{title}</h3>
            </div>

        </div>
    )
}

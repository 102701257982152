import React, { useState } from 'react'
import chef from '../assets/chef.png'
import plate from '../assets/plate.png'
import chefgreen from '../assets/chefgreen.png'
import plategreen from '../assets/plategreen.png'
import { motion, AnimatePresence } from 'framer-motion'
import validator from 'email-validator'
import submit from '../assets/submit.png'
import ring1 from '../assets/ring1.png'
import ring2 from '../assets/ring-2.png'
import FormButton from './FormButton'

export default function FormModal({ isOpen, setIsOpen, setBackdrop }) {
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [choice, setChoice] = useState('chef')
    const [email, setEmail] = useState('')
    const handleSubmit = (event) => {
        event.preventDefault()
        if (validator.validate(email)) {
            console.log(email, choice)
            setIsOpen(false)
            setIsConfirmed(true)
            setEmail('')
            setChoice('chef')
        } else {
            alert('enter valid email')
        }
    }

    return (
        <>

            {/* animate submitted modal. this comes last */}
            <AnimatePresence className="">
                {
                    isConfirmed &&
                    (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ type: 'spring', delay: 0.5 }}
                            className={`${setIsConfirmed ? 'flex' : 'hidden'} -translate-y-14 absolute mx-auto z-50 overflow-hidden md:w-4/5 xl:w-3/4 2xl:w-3/4 inset-x-0 justify-center shadow-xl bg-[#272727]`} >
                            <img src={ring1} alt="" className="absolute -z-10 -left-16 -bottom-16" />
                            <img src={ring2} alt="" className="absolute -z-10 -right-16 -top-16" />
                            <div className="space-y-8 pt-20 pb-10 text-center  transition">
                                <div className=" flex justify-center items-center">
                                    <img src={submit} alt="" className="" />
                                </div>
                                <h3 className="font-lara text-4xl lg:text-5xl tracking-[4px] text-white text-center">Submitted</h3>
                                <button className="text-[#a7a7a7] font-open-sans hover:underline transition tracking-[2px] ease-in-out" onClick={() => { setIsConfirmed(false); setBackdrop() }}>Close</button>
                            </div>
                        </motion.div>
                    )
                }
            </AnimatePresence>
            {/* animate form modal. this comes first */}
            <AnimatePresence className="">
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'spring' }}
                        className={`${isOpen ? 'flex' : 'hidden'} h-[80vh] absolute mx-auto z-50 overflow-hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:w-4/5 items-center lg:w-3/5 xl:w-4/5 2xl:w-4/5 w-full inset-x-0 justify-center shadow-xl bg-[#272727] `}
                    >

                        <img src={ring1} alt="" className="absolute -z-10 -left-16 -bottom-16" />
                        <img src={ring2} alt="" className="absolute -z-10 -right-16 -top-16" />
                        <div className="py-10 space-y-10">
                            <div className="space-y-4 tracking-[2px] text-center">
                                <h1 className="font-lara text-4xl lg:text-5xl">Be the first to know</h1>
                                <h2 className="lg:text-2xl text-xl font-open-sans">Leave your email to get invited</h2>
                            </div>
                            <form className="space-y-10 text-center" onSubmit={handleSubmit}>
                                <div className="grid grid-cols-2 gap-x-5">

                                    <motion.button
                                        whileTap={{ scale: 0.9 }}
                                        whileHover={{ scale: 1.1 }}
                                        type='button'
                                        onClick={() => { setChoice('chef') }}
                                        className="bg-[#212121] h-[25vh] "
                                    >
                                        {
                                            choice === 'chef' ?
                                                <FormButton icon={chefgreen} title={'Chef'} />
                                                :
                                                <FormButton icon={chef} title={'Chef'} />

                                        }


                                    </motion.button>
                                    <motion.button
                                        whileTap={{ scale: 0.9 }}
                                        whileHover={{ scale: 1.1 }}
                                        type='button'
                                        onClick={() => { setChoice('customer') }}
                                        className="bg-[#212121] h-[25vh] "
                                    >
                                        {
                                            choice === 'customer' ?
                                                <FormButton icon={plategreen} title={'Foodie'} />
                                                :
                                                <FormButton icon={plate} title={'Foodie'} />
                                        }


                                    </motion.button>
                                </div>
                                <div className="text-center space-y-6">

                                    <div className="email-input flex justify-center">
                                        <input
                                            type="text"
                                            placeholder='EMAIL'
                                            className="px-8 placeholder:text-white w-full placeholder:tracking-[2px] focus:outline-none font-extralight py-4 bg-[#191919] text-white"
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                    <div>

                                    </div>
                                    <div>
                                        <button
                                            className="font-lara tracking-[2px]  text-srve-green border border-srve-green py-4 hover:bg-[#4d4d4d] transition duration-500 delay-150 ease-in-out px-16 bg-[#181818] bg-opacity-50 text-2xl"
                                            type='submit'
                                        >
                                            Submit
                                        </button>
                                    </div>

                                    <button type='button' className="font-open-sans tracking-[2px] text-[#a7a7a7]" onClick={() => { setIsOpen(false); setBackdrop() }}>
                                        Cancel
                                    </button>



                                </div>




                            </form>
                        </div>



                    </motion.div>
                )
                }

            </AnimatePresence>


        </>
    )
}
